<template>
  <div class="page-wrapper">
    <h1 class="home-page-title">404 not found</h1>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  head: function () {
    return {
      title: {
        inner: '404',
      },
      meta: [
        {
          name: 'description',
          content: `${this.appTitle} - 404 page`,
          id: 'desc',
        },
      ],
    }
  },
  computed: mapState('app', ['appTitle']),
  mounted() {
    this.$router.push('/contact-us')
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
